import { apiURL } from "./http";
import https from 'https';

const authProvider = {
  login: ({ username, password }) => {
    const token = btoa(username + ":" + password);
    const agent = new https.Agent({
      rejectUnauthorized: false,
    });
    return fetch(apiURL + "/user/authorize", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
        "X-Sec-Token": "fab10dee-7260-4e66-bdc1-c98e38749daf"  // token zgodny z tokenem w htaccess api
      }),
      agent,
    })
      .then((response) => {
        console.log(response);
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("roles", JSON.stringify(result.roles));
      });
  },
  checkAuth: () =>
    window.localStorage.getItem("token") &&
    window.localStorage.getItem("roles")

        ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const roles = localStorage.getItem('roles') ;
    return roles ? Promise.resolve(JSON.parse(roles)) : Promise.reject();
  },
  logout: () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("roles");
    return Promise.resolve();
  },
  checkError: (error) => Promise.resolve(),

  // ...
};

export default authProvider;
